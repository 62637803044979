<template>
  <v-card>
    <v-card-title class="d-flex align-center">
      <span class="font-weight-semibold">Change Detection</span>
      <v-btn icon small class="ml-auto" @click="getChangeDetectionStats">
        <v-icon>
          {{ icons.mdiReload }}
        </v-icon>
      </v-btn>

      <ChangeDetectionFilter
        :close-on-content-click="false"
        :params="filters"
        :options="{
          cdSets,
          cdStatuses: statuses,
          cdClassificationTypes: classTypes,
          cdTypes: types,
        }"
        @on-reset="resetFilter"
        @change="getChangeDetectionStats"
      />
    </v-card-title>

    <v-card-text class="mb-6 mt-3">
      <v-row>
        <v-col
          @click="goToMIS(status)"
          v-for="status in statisticData"
          :key="status.id"
          cols="6"
          md="4"
          lg="2"
          class="d-flex align-center"
          style="cursor: pointer"
        >
          <v-avatar size="55" :color="status.color" rounded class="elevation-1">
            <v-icon dark color="white" size="35">
              {{ status.icon || icons.mdiChartPie }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ status.name }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ status.total }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <v-card height="501">
            <DetectionMapChart :wards="wardStats" :total="total" />
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card width="100%" outlined
            ><v-card-title class="d-flex align-center">
              <span>Detection Count By Status</span>
              <v-btn-toggle mandatory class="elevation-0 ml-auto" dense tile v-model="statusChartType">
                <v-btn icon small>
                  <v-icon>{{ icons.mdiChartPie }}</v-icon>
                </v-btn>
                <v-btn icon small>
                  <v-icon>{{ icons.mdiChartBar }}</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-card-title>
            <v-card-text style="height: 400px">
              <DetectionPieChart v-if="statusChartType === 0" :data="statusChartData" />
              <DetectionBarChart v-else :data="statusChartData" />
            </v-card-text>
            <v-card-actions class="pb-2"
              ><span style="font-weight: 600">Total: {{ total }}</span>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card width="100%" outlined
            ><v-card-title class="d-flex align-center"
              ><span>Top {{ (this.topWardsChartType + 1) * 5 }} Wards By Detection Count</span>
              <v-btn-toggle mandatory class="elevation-0 ml-auto" dense tile v-model="topWardsChartType">
                <v-btn icon small text> 5 </v-btn>
                <v-btn icon small text> 10 </v-btn>
                <v-btn icon small text> 15 </v-btn>
              </v-btn-toggle></v-card-title
            >
            <v-card-text style="height: 400px"><DetectionBarChart :data="topWardsChartData" /></v-card-text>
            <v-card-actions class="pb-2"
              ><span style="font-weight: 600">Total: {{ total }}</span>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card width="100%" outlined
            ><v-card-title class="d-flex align-center">
              <span>Detection Count By Classification</span>
              <v-btn-toggle mandatory class="elevation-0 ml-auto" dense tile v-model="classTypeChartType">
                <v-btn icon small>
                  <v-icon>{{ icons.mdiChartPie }}</v-icon>
                </v-btn>
                <v-btn icon small>
                  <v-icon>{{ icons.mdiChartBar }}</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-card-title>
            <v-card-text style="height: 400px">
              <DetectionPieChart v-if="classTypeChartType === 0" :data="classTypeChartData" />
              <DetectionBarChart v-else :data="classTypeChartData" />
            </v-card-text>
            <v-card-actions class="pb-2"
              ><span style="font-weight: 600">Total: {{ total }}</span>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiReload,
  mdiDotsVertical,
  mdiFilterOutline,
  mdiChartBar,
  mdiChartPie,
  mdiAccountCheckOutline,
  mdiMapMarkerCheckOutline,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline,
  mdiMinusCircleOutline,
} from '@mdi/js'
import { getWards, getCdClassificationTypes, getCdStatuses, getCdTypes } from '@/api/category'
import { getChangeDetectionSets } from '@/api/change-detection'
import { getChangeDetectionStats } from '@/api/dashboard'
import DetectionPieChart from './DetectionPieChart.vue'
import DetectionBarChart from './DetectionBarChart.vue'
import DetectionMapChart from './DetectionMapChart.vue'
import randomMaterialColor from 'random-material-color'
import ChangeDetectionFilter from '@/components/change-detection/ChangeDetectionFilter.vue'
export default {
  components: { DetectionPieChart, DetectionBarChart, DetectionMapChart, ChangeDetectionFilter },
  data() {
    return {
      showFilter: false,
      detectionStatusIcons: [
        mdiAccountCheckOutline,
        mdiMapMarkerCheckOutline,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiMinusCircleOutline,
      ],
      icons: {
        mdiReload,
        mdiDotsVertical,
        mdiFilterOutline,
        mdiChartBar,
        mdiChartPie,
      },
      cdSets: [],
      wards: [],
      classTypes: [],
      statuses: [],
      types: [],
      statusChartType: 0,
      classTypeChartType: 0,
      topWardsChartType: 1,
      filters: {
        wards: [],
        cdSets: [],
        statuses: [],
        classTypes: [],
        types: [],
        landTypes: [],
      },
      total: 0,
      statusStats: [],
      classTypeStats: [],
      wardStats: [],
      topWardColors: [
        '#FFB6C1', // Light Pink
        '#FFC0CB', // Pink
        '#FA8072', // Salmon
        '#FF7F50', // Coral
        '#F08080', // Light Coral
        '#FF6666', // Light Red
        '#FF0000', // Red
        '#DC143C', // Crimson
        '#B22222', // Firebrick
        '#8B0000', // Dark Red
        '#800000', // Maroon
        '#800020', // Burgundy
        '#660000', // Deep Red
        '#722F37', // Wine Red
        '#9B111E', // Ruby Red
      ].reverse(),
    }
  },
  computed: {
    totalResolvedCases() {
      return this.statusStats
        .filter(s => ['Authorized Detection', 'No Change Detection', 'Unauthorized Detection'].includes(s.name))
        .map(s => s.total)
        .reduce((a, b) => a + b, 0)
    },
    statisticData() {
      return [{ id: 0, name: 'Total', total: this.total, color: 'primary', icon: mdiChartBar }]
        .concat(this.statusStats.map((s, i) => ({ ...s, id: i + 1 })))
        .concat([
          {
            id: 6,
            name: 'Resolved Detection',
            total: this.totalResolvedCases,
            color: 'primary',
            icon: mdiChartBar,
          },
        ])
    },

    statusChartData() {
      return {
        labels: this.statusStats.map(status => status.name).concat('Resolved Detection'),
        datasets: [
          {
            label: 'Detection Count',
            data: this.statusStats.map(status => status.total).concat(this.totalResolvedCases),
            backgroundColor: this.statusStats.map(status => status.color).concat('#1e88e5'),
          },
        ],
      }
    },

    classTypeChartData() {
      return {
        labels: this.classTypes.map(status => status.name),
        datasets: [
          {
            label: 'Detection Count',
            data: this.classTypeStats.map(status => status.total),
            backgroundColor: this.classTypes.map(status => status.color),
          },
        ],
      }
    },

    topWardsChartData() {
      const wardStats = this.wardStats.slice(0, (this.topWardsChartType + 1) * 5)
      return {
        labels: wardStats.map(w => w.name),
        datasets: [
          {
            label: 'Detection Count',
            data: wardStats.map(w => w.total),
            backgroundColor: this.topWardColors.slice(0, (this.topWardsChartType + 1) * 5),
          },
        ],
      }
    },
  },
  methods: {
    goToMIS(status) {
      switch (status.id) {
        case 0:
          this.$router.push({ name: 'MIS', params: { tab: 0, filters: {} } })
          break
        case 1:
          this.$router.push({
            name: 'MIS',
            params: {
              tab: 0,
              filters: {
                statuses: [1, 2, 6, 11],
              },
            },
          })
          break
        case 2:
          this.$router.push({
            name: 'MIS',
            params: {
              tab: 0,
              filters: {
                statuses: [3, 5, 9, 10],
              },
            },
          })
          break
        case 3:
          this.$router.push({
            name: 'MIS',
            params: {
              tab: 3,
              filters: {
                types: ['authorized'],
              },
            },
          })
          break
        case 4:
          this.$router.push({
            name: 'MIS',
            params: {
              tab: 3,
              filters: {
                types: ['unauthorized'],
              },
            },
          })
          break
        case 5:
          this.$router.push({
            name: 'MIS',
            params: {
              tab: 3,
              filters: {
                types: ['no_change'],
              },
            },
          })
          break
        case 6:
          this.$router.push({ name: 'MIS', params: { tab: 3, filters: {} } })
          break
      }
    },

    resetFilter() {
      this.filters = {
        wards: [],
        plots: [],
        cdSets: [],
        statuses: [],
        classTypes: [],
        types: [],
      }
      this.getChangeDetectionStats()
      this.showFilter = false
    },

    async getCategories() {
      const [cdSets, wards, classTypes, statuses, types] = (
        await Promise.all([
          getChangeDetectionSets({ select: 'id*name', sortBy: 'id:desc' }),
          getWards({ select: 'id*name*geometry', sortBy: 'id' }),
          getCdClassificationTypes({ select: 'id*name', sortBy: 'id' }),
          getCdStatuses({ select: 'id*name' }),
          getCdTypes(),
        ])
      ).map(res => res.data)
      this.cdSets = cdSets
      this.wards = wards.map(ward => ({
        ...ward,
        color: randomMaterialColor.getColor(),
      }))
      this.classTypes = classTypes
      this.statuses = statuses
      this.types = types
      this.filters.cdSets = [cdSets[0]?.id]
    },

    async getChangeDetectionStats() {
      try {
        this.$loader(true)
        const { data } = await getChangeDetectionStats(this.filters)
        const { total, wards, statuses, classTypes } = data
        this.statusStats = statuses.map((s, i) => ({
          ...s,
          icon: this.detectionStatusIcons[i],
        }))
        this.classTypeStats = this.classTypes.map(ct => {
          const stat = classTypes.find(c => c.id === ct.id)
          return {
            total: stat ? stat.total : 0,
          }
        })
        this.wardStats = wards.map(w => {
          const { color, name, geometry } = this.wards.find(ward => ward.id === w.id)
          return {
            ...w,
            color,
            name,
            geometry,
          }
        })
        this.total = total
      } catch (error) {
        console.error(error)
      } finally {
        this.$loader(false)
      }
    },
  },

  created() {
    this.getCategories().then(() => this.getChangeDetectionStats())
  },
}
</script>
