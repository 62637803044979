<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">User </span>
      <v-spacer></v-spacer>
      <v-btn icon small class="me-n3 mt-n2" @click="getUserStats">
        <v-icon>
          {{ icons.mdiReload }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="mb-6 mt-3">
      <v-row>
        <v-col v-for="data in statisticsData" :key="data.title" cols="6" md="4" lg="2" class="d-flex align-center">
          <v-avatar size="55" :color="data.color" rounded class="elevation-1">
            <v-icon dark color="white" size="35">
              {{ data.icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ data.title }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ data.total }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiReload,
  mdiAccountOutline,
  mdiShieldAccountOutline,
  mdiCellphone,
  mdiAccountGroupOutline,
  mdiChartBar,
  mdiTimerSandEmpty,
  mdiScaleBalance,
} from '@mdi/js'
import { getUserStats } from '@/api/dashboard'
export default {
  data() {
    return {
      icons: {
        mdiReload,
        mdiScaleBalance,
      },
      statisticsData: [],
      total: 0,
      roles: [
        { id: 1, title: 'Admin', icon: mdiShieldAccountOutline, color: '#2196F3' },
        {
          id: 2,
          title: 'Field Officer',
          icon: mdiCellphone,
          color: '#4CAF50',
        },
        {
          id: 3,
          title: 'Official',
          icon: mdiAccountOutline,
          color: '#673AB7',
        },

        { id: 4, title: 'Citizen', icon: mdiAccountGroupOutline, color: '#FFC107' },
        { id: 5, title: 'Legal Officer', icon: mdiScaleBalance, color: '#673AB7' },
      ],
    }
  },

  methods: {
    async getUserStats() {
      try {
        this.$loader(true)
        const { data } = await getUserStats()
        const { roles, total, pending } = data
        this.statisticsData = [
          { id: 0, title: 'Total', icon: mdiChartBar, color: 'primary', total },
          { id: -1, title: 'Pending', icon: mdiTimerSandEmpty, color: 'warning', total: pending },
        ].concat(roles.map(r => ({ ...r, ...this.roles.find(role => role.id === r.id) })))
        this.total = total
      } catch (error) {
        console.log(error)
      } finally {
        this.$loader(false)
      }
    },
  },
  created() {
    this.getUserStats()
  },
}
</script>
