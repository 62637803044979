<template>
  <div class="full-height" style="overflow: hidden">
    <Map ref="dashboardMap" :tools="tools" :zoom="zoom" :center="center" :style="{ width: '100%' }">
      <div style="position: absolute; top: 10px; right: 10px; z-index: 2" class="d-flex">
        <div v-for="(color, idx) in ramp" :key="color">
          <div :style="{ backgroundColor: color, width: '50px', height: '10px' }"></div>
          <span style="font-size: 11px; font-weight: bold" :style="{ color }">{{ step * idx }}</span>
        </div>
      </div>
      <v-card flat style="position: absolute; bottom: 10px; left: 10px; z-index: 2" class="px-2 py-1">
        <span style="font-weight: 600">Total: {{ total }}</span>
      </v-card>
    </Map>
  </div>
</template>
<script>
import Map from '@/components/map/Map.vue'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import colormap from 'colormap'
import { Fill, Stroke, Style } from 'ol/style'
import { Feature } from 'ol'
import GeoJSON from 'ol/format/GeoJSON'
import Overlay from 'ol/Overlay.js'

export default {
  props: {
    wards: [],
    total: Number,
  },
  components: { Map },
  data() {
    return {
      zoom: 11,
      center: [85.3321, 23.35],

      tools: [
        {
          name: 'Scale',
          position: { bottom: '10px', right: '220px' },
        },
        {
          name: 'MousePosition',
          position: { bottom: '10px', right: '10px' },
        },
        {
          name: 'Zoom',
          position: { bottom: '50px', right: '10px' },
        },
        {
          name: 'ExtentControl',
          position: { bottom: '105px', right: '10px' },
        },
        {
          name: 'TileSwitcher',
          position: { bottom: '83px', right: '42px', size: '45' },
        },
        {
          name: 'Geolocation',
          position: { bottom: '50px', right: '42px' },
        },
        {
          name: 'FullScreen',
          position: { bottom: '50px', right: '74px' },
        },
        {
          name: 'Measure',
          position: { bottom: '50px', right: '106px' },
        },
      ],
      steps: 6,
      min: 0,
      layer: null,
      source: new VectorSource(),
      hightlightSource: new VectorSource(),
      infoTooltipElement: null,
      infoTooltip: null,
    }
  },
  computed: {
    step() {
      const range = this.max - this.min
      if (range % this.steps === 0) return range / this.steps + 1
      return Math.ceil(range / this.steps)
    },
    ramp() {
      return colormap({
        colormap: 'jet',
        nshades: this.steps,
        alpha: 0.9,
        format: 'rgbaString',
      })
    },
    max() {
      return this.wards.reduce((max, ward) => Math.max(max, ward.total), 0)
    },
  },

  watch: {
    wards() {
      if (this.wards.length === 0) return
      this.source.clear()
      this.wards.forEach(ward => {
        const feature = new Feature(
          new GeoJSON().readGeometry(ward.geometry, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857',
          }),
        )
        feature.set('id', ward.id)
        feature.set('total', ward.total)
        feature.set('name', ward.name)
        this.source.addFeature(feature)
      })
      this.$refs.dashboardMap.map.getView().fit(this.source.getExtent())
    },
  },

  methods: {
    clickHandler(evt) {
      this.layer.getFeatures(evt.pixel).then(features => {
        if (features.length === 0) return
        this.$router.push({ name: 'MIS', params: { tab: 0, filters: { wards: [features[0].get('id')] } } })
      })
    },
    pointerMoveHandler(evt) {
      if (evt.dragging) {
        return
      }
      this.layer.getFeatures(evt.pixel).then(features => {
        this.hightlightSource.clear()
        if (features.length === 0) {
          this.infoTooltipElement.classList.add('d-none')
          return
        }
        this.$refs.dashboardMap.map.getViewport().style.cursor = 'pointer'
        const feature = features[0]
        this.hightlightSource.addFeature(feature)
        this.infoTooltipElement.innerHTML = `<p class="mb-0">Ward: ${feature.get(
          'name',
        )}</p><p class="mb-0">Detection: ${feature.get('total')}</p>`
        this.infoTooltip.setPosition(evt.coordinate)
        this.infoTooltipElement.classList.remove('d-none')
      })
    },
    getColor(feature) {
      const total = +feature.get('total')
      const index = Math.floor(total / this.step)
      return this.ramp[index]
    },
    createInfoTooltip() {
      if (this.infoTooltipElement) {
        this.infoTooltipElement.parentNode.removeChild(this.infoTooltipElement)
      }
      this.infoTooltipElement = document.createElement('div')
      this.infoTooltipElement.className = 'ol-tooltip '
      this.infoTooltip = new Overlay({
        element: this.infoTooltipElement,
        offset: [15, 0],
        positioning: 'center-left',
      })

      this.$refs.dashboardMap.map.addOverlay(this.infoTooltip)
    },
  },

  mounted() {
    this.layer = new VectorLayer({
      source: this.source,
      style: feature => {
        return new Style({
          fill: new Fill({
            color: this.getColor(feature),
          }),
          stroke: new Stroke({
            color: 'rgba(255,255,255,0.8)',
          }),
        })
      },
    })
    const hightlightLayer = new VectorLayer({
      source: this.hightlightSource,
      style: new Style({
        stroke: new Stroke({
          color: 'red',
          width: 2,
        }),
      }),
    })
    this.$refs.dashboardMap.map.addLayer(this.layer)
    this.$refs.dashboardMap.map.addLayer(hightlightLayer)
    this.createInfoTooltip()
    this.$refs.dashboardMap.map.on('pointermove', this.pointerMoveHandler)
    this.$refs.dashboardMap.map.on('click', this.clickHandler)
  },
}
</script>
