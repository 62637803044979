<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Citizen Complaint </span>
      <v-spacer></v-spacer>
      <v-btn icon small class="me-n3 mt-n2" @click="getComplaintStats">
        <v-icon>
          {{ icons.mdiReload }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="mb-6 mt-3">
      <v-row>
        <v-col
          @click="goToMIS(data)"
          v-for="data in statisticsData"
          :key="data.title"
          cols="6"
          md="4"
          lg="2"
          class="d-flex align-center"
          style="cursor: pointer"
        >
          <v-avatar size="55" :color="data.color" rounded class="elevation-1">
            <v-icon dark color="white" size="35">
              {{ data.icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ data.title }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ data.total }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiSend,
  mdiCheckCircle,
  mdiForward,
  mdiWrench,
  mdiClose,
  mdiReload,
  mdiChartBar,
  mdiProgressClock,
} from '@mdi/js'
import { getComplaintStats } from '@/api/dashboard'
export default {
  data() {
    return {
      icons: {
        mdiReload,
      },
      statisticsData: [],
      total: 0,
      statuses: [
        { id: 1, title: 'Submitted for review', icon: mdiSend, color: '#2196F3' },
        {
          id: 2,
          title: 'Complaint accepted',
          icon: mdiCheckCircle,
          color: '#FFC107',
        },
        {
          id: 3,
          title: 'Complaint forwarded to RMC',
          icon: mdiForward,
          color: '#673AB7',
        },

        { id: 4, title: 'Action taken', icon: mdiWrench, color: 'error' },
        { id: 5, title: 'Case cleared', icon: mdiClose, color: '#4CAF50' },
        { id: 6, title: 'Under Process', icon: mdiProgressClock, color: '#673AB7' },
      ],
    }
  },

  methods: {
    goToMIS(data) {
      switch (data.id) {
        case 0:
          this.$router.push({ name: 'MIS', params: { tab: 1, filters: {} } })
          break
        case 1:
          this.$router.push({
            name: 'MIS',
            params: {
              tab: 1,
              filters: {
                statuses: [1],
              },
            },
          })
          break
        case 2:
          this.$router.push({
            name: 'MIS',
            params: {
              tab: 1,
              filters: {
                statuses: [2],
              },
            },
          })
          break
        case 3:
          this.$router.push({
            name: 'MIS',
            params: {
              tab: 1,
              filters: {
                statuses: [3],
              },
            },
          })
          break
        case 4:
          this.$router.push({
            name: 'MIS',
            params: {
              tab: 1,
              filters: {
                statuses: [4],
              },
            },
          })
          break
        case 5:
          this.$router.push({
            name: 'MIS',
            params: {
              tab: 1,
              filters: {
                statuses: [5],
              },
            },
          })
          break
        case 6:
          this.$router.push({
            name: 'MIS',
            params: {
              tab: 1,
              filters: {
                statuses: [6],
              },
            },
          })
          break
      }
    },

    async getComplaintStats() {
      try {
        this.$loader(true)
        const { data } = await getComplaintStats()
        const { statuses, total } = data
        this.statisticsData = [{ id: 0, title: 'Total', icon: mdiChartBar, color: 'primary', total }].concat(
          this.statuses.map(s => ({ ...s, ...(statuses.find(status => status.id === s.id) || { total: 0 }) })),
        )
        this.total = total
      } catch (error) {
        console.log(error)
      } finally {
        this.$loader(false)
      }
    },
  },
  created() {
    this.getComplaintStats()
  },
}
</script>
